body {
    margin: 0;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    overflow-x: hidden;
}
.rdw-editor-wrapper{
    width: 94%;
}
.editor-custom .rdw-editor-wrapper{
    width: 100%;
}
.rdw-editor-toolbar{
    border: 1px solid rgba(0, 0, 0, 0.26) !important;
    border-radius: 4px 4px 0 0 !important;
    margin-bottom: 0 !important;
}
.rdw-editor-main{
    border: 1px solid rgba(0, 0, 0, 0.26);
    border-top: 0;
    margin-bottom: 30px;
    border-radius: 0 0 4px 4px;
    padding: 10px;
}
/* @media (max-width:576px) {
    .static-banner-wrapper{
        display: none;
    }
} */
/* .static-slide *:not(.vjs-volume-bar){
    margin-top: 0 !important;
    margin-bottom: 0 !important;
} */
@media (max-width:576px) {
    .static-slide{
        padding-bottom: 30%;
    }
}
@media (max-width:1199px) {
    .static-slide .carousel__inner-slide > div{
        padding: 0px 10px !important;
    }
}

/* VIDEO JS THEME FANTASY */
/* .vjs-custom .vjs-big-play-button{
    color: #0d9ddb !important;
}
.vjs-custom.vjs-big-play-button:focus, .vjs-custom:hover .vjs-big-play-button{
    background-color: transparent !important;
    color: #0d9ddb !important;
}
.vjs-custom .vjs-play-progress,
.vjs-custom .vjs-slider-bar {
  background: #f6a71c !important;
}
.vjs-custom .vjs-play-progress:before{
    background-color: #f6a71c !important;
} */
/* VIDEO JS THEME FANTASY END*/

/* VIDEO JS THEME FOREST */
.vjs-custom .vjs-big-play-button{
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='88' fill='%230d9ddb'%3E%3Cpath fill-rule='evenodd' d='M44 88C19.738 88 0 68.262 0 44S19.738 0 44 0s44 19.738 44 44-19.738 44-44 44zm0-85C21.393 3 3 21.393 3 44c0 22.608 18.393 41 41 41s41-18.392 41-41C85 21.393 66.607 3 44 3zm16.063 43.898L39.629 60.741a3.496 3.496 0 01-3.604.194 3.492 3.492 0 01-1.859-3.092V30.158c0-1.299.712-2.483 1.859-3.092a3.487 3.487 0 013.604.194l20.433 13.843a3.497 3.497 0 01.001 5.795zm-1.683-3.311L37.946 29.744a.49.49 0 00-.276-.09.51.51 0 00-.239.062.483.483 0 00-.265.442v27.685c0 .262.166.389.265.442.1.053.299.118.515-.028L58.38 44.414A.489.489 0 0058.6 44a.49.49 0 00-.22-.413z'/%3E%3C/svg%3E") !important;
}
.vjs-custom.vjs-big-play-button:focus, .vjs-custom:hover .vjs-big-play-button{
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='88' fill='%230d9ddb'%3E%3Cpath fill-rule='evenodd' d='M44 88C19.738 88 0 68.262 0 44S19.738 0 44 0s44 19.738 44 44-19.738 44-44 44zm0-85C21.393 3 3 21.393 3 44c0 22.608 18.393 41 41 41s41-18.392 41-41C85 21.393 66.607 3 44 3zm16.063 43.898L39.629 60.741a3.496 3.496 0 01-3.604.194 3.492 3.492 0 01-1.859-3.092V30.158c0-1.299.712-2.483 1.859-3.092a3.487 3.487 0 013.604.194l20.433 13.843a3.497 3.497 0 01.001 5.795zm-1.683-3.311L37.946 29.744a.49.49 0 00-.276-.09.51.51 0 00-.239.062.483.483 0 00-.265.442v27.685c0 .262.166.389.265.442.1.053.299.118.515-.028L58.38 44.414A.489.489 0 0058.6 44a.49.49 0 00-.22-.413z'/%3E%3C/svg%3E") !important;
}
.vjs-custom .vjs-play-control .vjs-icon-placeholder:before{
    color: #f6a71c !important;
}
.vjs-custom .vjs-volume-level:before{
    border-bottom: 2em solid #f6a71c !important;
}
.vjs-custom .vjs-volume-bar:before{
    border-bottom: 2em solid #fff !important;
}
.vjs-custom .vjs-play-progress {
  background: #f6a71c !important;
}
.vjs-custom .vjs-control:not(.vjs-disabled):not(.vjs-time-control):hover{
    color: #f6a71c !important;
}
.vjs-custom .vjs-play-control:hover .vjs-icon-placeholder:before{
    background-color: #f6a71c !important;
    color: #fff !important;
}
/* VIDEO JS THEME FOREST END*/

.vjs-poster{
    background-color: #0d9ddb !important;
}
.video-js:not(.vjs-has-started) .vjs-tech{
    border: 1px solid #0d9ddb;
}
.active-text{
    color: #28a745;
    font-weight: bold !important;
}
.inactive-text{
    color: red;
    font-weight: bold !important;
}
.delete-cert-btn{
    color: red !important;
    border: 1px solid red !important;
    min-width: 30px !important;
    padding: 5px 10px !important;
}
.delete-cert-btn .MuiButton-startIcon{
    margin: 0;
}
.custom-input{
    width: 100%;
}
/*custom certificate preview styles */
.cert-module-parent{
    display: flex;
    align-items: center;
    flex: 1 1 0%;
}
.cert-form-wrap{
    width: 40%;
}
.cert-preview-wrap{
    width: 60%;
}
@media (max-width:1199px) {
    .cert-module-parent{
        display: block;
    }
    .cert-form-wrap{
        width: 100%;
    }
    .cert-preview-wrap{
        width: 100%;
        margin-left: 0 !important;
    }
}

@font-face {
    font-family: HurmeGeometricSans;
    src: url('fonts/HurmeGeometricSans4.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: HurmeGeometricSans;
    src: url('fonts/HurmeGeometricSans4 Bold.otf');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: HurmeGeometricSans;
    src: url('fonts/HurmeGeometricSans4 SemiBold.otf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: HurmeGeometricSans;
    src: url('fonts/HurmeGeometricSans4 SemiBoldOblique.otf');
    font-weight: bold;
    font-style: italic;
}

.cert-preview-wrap{
    font-family: 'HurmeGeometricSans', sans-serif;
    font-weight: normal;
    /* font-size: 2.5rem; */
    line-height: 1.3rem;
    text-align: center;
    color: #fff;
}
.cert-bg-wrap{
    background-size: contain;
    background-repeat: no-repeat;
    /* min-height: 35vmax; */
    pointer-events: none;
    cursor: default;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    display: flex;
    align-items: center;
    position: relative;
}
.cert-preview-content{
    font-size: 1rem;
    margin-left: 7%;
    margin-right: 7%;
}
.cert-preview-content p:not(:empty){
    margin-bottom: 2.5rem;
}
.cert-preview-content strong{
    font-weight: 800;
}
.cert-bg-img{
    width: 100%;
}
.cert-preview-img{
    position: absolute;
    top: 6%;
    left: 2%;
}
.cert-preview-parent{
    position: absolute;
    width: 100%;
    zoom: 50%;
    margin-top: 5%;
}
@media (max-width:576px) {
    .cert-preview-img img{
        width: 40px;
    }
    .cert-preview-content{
        line-height: 3rem;
    }
    .cert-preview-parent{
        zoom: unset;
        transform: scale(0.2);
        transform-origin: 0;
        width: 1635px;
    }
    .cert-preview-content p:not(:empty){
        margin-bottom: 3rem;
    }
}
.certificate-view-parent{
    display: flex;
    /* align-items: center; */
    flex: 1 1 0%;
}
.certificate-image-wrap{
    width: 80%;
}
.certificate-details-wrap{
    width: 20%;
}
@media (max-width:1440px) {
    .certificate-image-wrap{
        width: 70%;
    }
    .certificate-details-wrap{
        width: 30%;
    }
}
@media (max-width:991px) {
    .certificate-view-parent{
        display: block;
    }
    .certificate-image-wrap{
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .certificate-details-wrap{
        width: 100%;
        margin-top: 30px;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
.certificate-image-wrap img{
    max-width: 100%;
}
.custom-cause-card{
    width: 100%;
    max-width: 100%;
}
.custom-certificate-btn{
    color: rgba(255,255,255,0.9);
    font-size: 1.1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.custom-certificate-btn.first{
    margin-right: 10px;
}
/* .cert-btn-icon{
    font-size: 32px !important
} */
.custom-cert-link{
    color: inherit !important;
    text-decoration: none !important;
}
.custom-dropdown-parent{
    margin-top: 27px;
}
.custom-cause-wrap{
    margin-bottom: 20px;
}

/* admin panel certificates preview */
.admin-cert-preview-wrap {
    font-family: 'HurmeGeometricSans', sans-serif;
    font-weight: normal;
    line-height: 1.3rem;
    text-align: center;
    color: #fff;
}
.admin-cert-bg-wrap {
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
    cursor: default;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    display: flex;
    align-items: center;
    position: relative;
}
.admin-cert-bg-img{
    width: 94%;
}
.admin-cert-preview-parent {
    position: absolute;
    width: 94%;
    zoom: 50%;
    margin-top: 5%;
}
.admin-cert-preview-content {
    font-size: 1rem;
    margin-left: 7%;
    margin-right: 7%;
}
.admin-cert-preview-content p:not(:empty){
    margin-bottom: 2.5rem;
}
.admin-cert-preview-content strong{
    font-weight: 800;
}
.admin-cert-bg-img.edit, .admin-cert-preview-parent.edit{
    width: 100%;
}
.admin-cert-preview-img{
    position: absolute;
    top: 7%;
    left: 2%;
}
.admin-sms-btn-icon{
    margin-top: -3px;
}
.verified-phone-text{
    margin-top: 0;
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    padding: 7px 10px;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.phone-div-hidden{
    display: none;
}
.account-phone-verify-btn{
    margin-top: -10px !important;
    margin-bottom: 10px !important;
}
.fc-w-100{
    width: 100%;
}
.sms-info-box{
    padding: 10px;
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
    border-radius: 6px;
}
.sms-info-first, .sms-info-second{
    margin: 0;
}
.sms-info-words, .sms-info-number{
    font-weight: bold;
    font-size: 16px;
}
.sms-info-example{
    font-weight: bold;
}
.sms-info-second{
    font-size:15px;
}
.sms-info-emoji{
    font-size:18px;
}
.sms-info-words{
    margin: .5em 0;
}
.sms-info-third{
    margin: .5em 0;
    font-size: 13px;
    font-style: italic;
}
.admin-sms-wrap{
    border: 2px solid #28a745;
    border-radius: 6px;
    padding: 10px;
    padding-bottom: 0;
}
.admin-sms-heading{
    font-weight: bold;
    color: #155724;
    margin-bottom: 0.5em;
    margin-top: 1.5em;
}
.charity-sms-wrap{
    border: 2px solid #ffc107;
    border-radius: 6px;
    padding: 10px;
    padding-bottom: 0;
    margin-bottom: 10px;
}
.charity-sms-wrap >*:first-child{
    margin-bottom: 10px;
}
.charity-sms-heading{
    font-weight: bold;
    color: #856404;
    margin-bottom: 0.5em;
    margin-top: 1.3em;
}
.view-value-yes{
    color: green;
    font-weight: bold;
    margin: 0.5em 0;
    border: 1px solid rgba(0, 0, 0, 0.26);
    padding: 3px 10px;
    border-radius: 4px;
    min-width:60px;
    text-align: center;
}
.view-value-no{
    color: red;
    font-weight: bold;
    margin: 0.5em 0;
    border: 1px solid rgba(0, 0, 0, 0.26);
    padding: 3px 10px;
    border-radius: 4px;
    min-width:60px;
    text-align: center;

}
.sms-custom-label{
    margin: 0;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.54);
    margin-bottom: 2px;
}
.sms-custom-content{
    border: 1px solid rgba(0, 0, 0, 0.26);
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
    margin-bottom: 10px;
}

/*static news CSS*/
.news-heading{
    text-align: center;
    font-size: 2.5rem;
    color: rgb(51, 51, 51);
}
.news-container {
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
@media (min-width: 600px) {
    .news-container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .news-container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .news-container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .news-container {
        max-width: 1200px;
    }
}
@media (max-width: 576px) {
    .news-container {
        max-width: 100%;
        padding: 0;
    }
}
.news-row{
    display:flex;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.news-wrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    position: relative;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #dddddd;
}

.news-wrap:first-child{
    border-top: 1px solid #dddddd;
}

.news-image{
    flex: 0 0 25%;
    max-width: 25%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.news-info{
    flex: 0 0 75%;
    max-width: 75%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.news-image img{
    width: 100%;
}
.news-info a.news-link{
    text-decoration: none;
    color: rgb(51, 51, 51);
    font-size: 0.75rem; 
    cursor: pointer;
}
.news-info p{
    font-size: 15px;
}
.news-info a.news-read-more{
    color: #f6a71c;
    text-decoration: none;
    font-weight: 600;
}
.news-info a:hover{
    text-decoration: none;
    color: #0d9ddb;
}
.news-empty-space{
    min-height: 500px;
}
/*static news mobile responsive*/
@media (min-width:577px) and (max-width:991px) {
    .news-image{
        flex: 0 0 40%;
        max-width: 40%;
    }
}
@media (max-width:991px) {
    .news-wrap{
        padding-right: 15px;
        padding-left: 15px;
    }
}
@media (max-width:576px) {
    .news-image, .news-info{
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
    }
    .news-wrap{
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
    }
    .news-info a.news-link{
        font-size: 0.6rem
    }
}
/*static news CSS END*/
.report-date-parent{
    display: flex;
    justify-content: space-between;
}
.report-date-parent > div{
    width: 100%
}
.report-date-parent > div:first-child{
    margin-right: 20px
}
.report-charity-parent{
    display: flex;
    justify-content: space-between;
}
.report-charity-parent > div:first-child{
    width: 49%;
}
.update-button-wrap{
    margin-top: 6px;
}
.report-info-parent{
    margin-top: -24px;
    display: flex;
    justify-content: space-between;
}
.report-info-parent.charity-report{
    margin-top:0;
}
.report-info-date-wrap{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
}
.report-info-date-wrap p:first-child{
    font-weight: bold;
    margin-right: 5px;
}
.report-info-total-wrap{
    display: flex;
    font-size: 16px;
}
.report-info-total-wrap p:first-child{
    font-weight: bold;
    margin-right: 5px;
}
a.dashboard-link{
    color: cornflowerblue;
    text-decoration: none;
}
a.dashboard-link:hover{
    color: #0d9ddb;
    text-decoration: underline;
}
.dashboard-detail-btn{
    margin-left: 10px !important;
}
.MuiDialog-paperWidthSm{
    max-width: 800px !important;
}
.dashboard-list{
    padding-inline-start: 20px;
}
.dashboard-false-list{
    padding-inline-start: 0;
    list-style: none;
}
.dashboard-table tbody tr:nth-child(odd) {
    background: #eee;
}
.comp-photo-rules{
    margin: 0;
}
.competition-details-wrap img{
    max-width: 100%;
}
.text-underline{
    text-decoration: underline;
}
.admin-uinfo-wrap span{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    padding: 5px 0;
}
.admin-uinfo-wrap span svg{
    color: #0d9ddb;
    margin-right: 5px;
}
.return-cause-btn{
    width: 90%;
    margin-bottom: 16px !important;
}
.comp-btns-wrap{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #c4c4c4;
    padding-top: 10px;
}
.comp-btns-wrap button:first-child{
    margin-right: 10px;
}
@media (max-width:1199px) {
    .comp-btns-wrap{
        display: block;
    }
    .comp-btns-wrap button{
        margin-top: 10px;
    }
}
.comp-spinner-wrap{
    text-align: center;
    margin-top: 10px;
}
.admin-comp-file-wrap{
    width: 94%;
}
.admin-comp-download-wrap{
    width: 94%;
    margin-top: 16px;
}
.admin-comp-download-btn{
    margin-left: 10px !important;
}
.comp-download-wrap{
    margin-bottom: 10px;
}
.comp-notice-wrap {
    margin-top: 20px;
}
.comp-notice-wrap h2{
    margin-top: 0;
    margin-bottom: 10px;
}
.comp-notice-wrap ul{
    padding-inline-start: 20px;
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: disclosure-closed;
}
.comp-notice-wrap ul li{
    margin: 0;
    margin-bottom: 7px;
    font-weight: 600;
}
.comp-checkbox-wrap{
    margin-top: -10px;
}
.comp-check-desc{
    font-weight: 500;
}
.comp-checkbox-wrap > span:first-child{
    padding-left: 16px;
}
.comp-checkbox-wrap .Mui-checked .MuiSvgIcon-root{
    fill: #33cc2a;
}
.manual-cert-parent{
    max-width: 1280px;
}
.four-five-launch-page{
    align-items: center;
    margin-bottom: 100px;
}
.four-five-btn-wrap{
    width: 100%;
    max-width: 1280px;
}
.four-five-btn-wrap button{
    margin: 0 !important; 
}
.four-five-view-btn-wrap{
    position: absolute;
    top: 100%;
}
.four-five-view-btn{
    background: #0d9ddb;
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 16px;
    display: block;
    margin-bottom: 10px;
}
.four-five-view-link-wrap{
    position: absolute;
    top: 100%;
    margin-top: 50px;
}
.four-five-view-link{
    text-decoration: none;
    color: cornflowerblue;
}
.custom-date-wrap{
    position: relative;
}
.custom-date-label{
    color: rgba(0, 0, 0, 0.54);
    font-size: 13px;
    margin: 0;
    position: absolute;
    z-index: 1;
    left: 10px;
    top: -7px;
    background: #fff;
    padding: 0 5px;

}
input.custom-date{
    font-size: 16px;
    padding: 10px 14px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.26);
    border:0;
    width: calc(100% - 48px);
    font-family: unset;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.26) inset;
}
input.custom-date.sign-up{
    width: 100%;
}
input.custom-date:focus, input.custom-date:focus-visible{
    box-shadow: 0px 0px 0px 2px #f6a71c inset;
    outline: 0;
}
.static-menu-link{
    text-decoration: none !important;
}
.comp-notice-wrap ul li a{
    text-decoration: underline;
    color: #0d9ddb;
}
.comp-login-btn{
    padding: 0 !important;
    text-decoration: underline !important;
    color: #0d9ddb !important;
    font-weight: 600 !important;
}
.comp-login-btn:hover{
    background: unset !important;
}